import { CAMERA_X, CAMERA_Y, HEIGHT, WIDTH } from "./constants";
import { sleep } from "./util";

export const Camera = new class {
  constructor() {
    // Sizing
    this.scale = 1;
    this.width = WIDTH;
    this.height = HEIGHT;
    // Canvas element offset (safe area)
    this.leftOffset = 0;
    this.topOffset = 0;
    // Current position
    this.defaultX = this.x = WIDTH * CAMERA_X;
    this.defaultY = this.y = HEIGHT * CAMERA_Y;
    // For transitions
    this.startT = 0;
    this.startX = this.x;
    this.startY = this.y;
    this.deltaX = 0;
    this.deltaY = 0;
    this.endDuration = 1000;
    this.endX = this.x;
    this.endY = this.y;
    // For CSS transforms of the entire canvas
    this.allowTransform = true;
    this.setTransform = null;
    this.transformTimeout = 0;
  }

  get style() {
    const DPI = window.devicePixelRatio || 1;
    return {
      width: `${Math.ceil(this.width / DPI)}px`,
      height: `${Math.ceil(this.height / DPI)}px`,
      left: `${this.leftOffset}px`,
      top: `${this.topOffset}px`,
    };
  }

  calculateScale() {
    const MARGIN = 16;
    const DPI = window.devicePixelRatio || 1;
    const area = this.getSafeArea();
    this.scale = DPI * Math.min(
      (area.width - MARGIN * 2) / WIDTH,
      (area.height - MARGIN * 2) / HEIGHT
    );
    this.width = Math.ceil(this.scale * WIDTH);
    this.height = Math.ceil(this.scale * HEIGHT);
    this.leftOffset = Math.max(0, (this.width / DPI - area.width) * 0.5);
    this.topOffset = Math.max(0, (this.height / DPI - area.height) * 0.5);
    return this.style;
  }

  frame(ctx, timestamp, delta) {
    if (this.startT) {
      const t = (timestamp - this.startT) / this.endDuration;
      if (t >= 1) {
        this.x = this.endX;
        this.y = this.endY;
        this.startT = 0;
      } else {
        const lerp = 0.25 * Math.sin(t * Math.PI * 0.5) +
        0.75 * (1 - Math.pow(1 - t, 2));
        this.x = this.startX + lerp * this.deltaX;
        this.y = this.startY + lerp * this.deltaY;
      }
    }
    ctx.scale(this.scale, this.scale);
    ctx.translate(this.x, this.y);
  }

  getSafeArea() {
    const style = window.getComputedStyle(document.documentElement);
    const top = parseInt(style.getPropertyValue('--inset-top'));
    const right = parseInt(style.getPropertyValue('--inset-right'));
    const bottom = parseInt(style.getPropertyValue('--inset-bottom'));
    const left = parseInt(style.getPropertyValue('--inset-left'));
    return {
      top, right, bottom, left,
      width: window.innerWidth - left - right,
      height: window.innerHeight - top - bottom,
    };
  }

  // Sets a new camera value
  set(x, y, duration = 3000) {
    if (x == null) {
      x = this.x;
    }
    if (y == null) {
      y = this.y;
    }
    if (!duration) {
      this.x = x;
      this.y = y;
      this.startT = 0;
      return;
    }
    this.startT = performance.now();
    this.startX = this.x;
    this.startY = this.y;
    this.deltaX = x - this.x;
    this.deltaY = y - this.y;
    this.endDuration = duration;
    this.endX = x;
    this.endY = y;
    return sleep(duration);
  }

  // Causes the canvas to wobble
  shake(x, y) {
    if (!this.allowTransform) {
      return;
    }
    this.setTransform?.(`perspective(1000px) rotateX(${
      -15 * y / HEIGHT
    }deg) rotateY(${
      15 * x / WIDTH
    }deg)`);
    if (this.transformTimeout) {
      clearTimeout(this.transformTimeout);
    }
    this.transformTimeout = setTimeout(() => {
      this.setTransform?.(`perspective(1000px)`);
      this.transformTimeout = 0;
    }, 250);
  }

  // Resets to the default camera value
  reset(duration = 0) {
    return this.set(this.defaultX, this.defaultY, duration);
  }

}();

// Testing
window.Camera = Camera;
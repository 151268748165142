const Settings = new class {
  constructor() {
    this.load();
  }

  get json() {
    return {
      fullscreen: Boolean(document.fullscreenElement),
      sound: this.sound ?? true,
      wobble: this.wobble ?? window.devicePixelRatio === 1,
    };
  }

  getStorage() {
    return JSON.parse(localStorage.getItem('hexyl-settings') || '{}');
  }

  load() {
    const current = this.json;
    const storage = this.getStorage();
    for (const key in current) {
      if (key in storage) {
        this[key] = storage[key];
      } else {
        this[key] = current[key];
      }
    }
  }
  
  saveStorage(json) {
    localStorage.setItem('hexyl-settings', JSON.stringify(json));
  }

  set(name, value) {
    if (name in this.json === false) {
      return;
    }
    this[name] = value;
    this.saveStorage({
      ...this.getStorage(),
      [name]: value,
    });
    return value;
  }
}();

window.Settings = Settings;

export default Settings;
// import { Game } from "./game";
import Jungle from "./jungle";
import Settings from "./settings";
import { sleep } from "./util";

// const TRUSTED_EVENTS = ['mousedown', 'touchstart'];
const PITCH_CYCLE = [0, 1, 2, 1, 0, -1, -2, -1];
// const PITCH_CYCLE = [0, 1, 2, -2, -1];

const Sound = new (class {
  constructor() {
    this._isMuted = !Settings.sound;
    this.context = null;
    this.buffers = {};
    // Used for cycling through pitches
    this.cycle = 0;
    this.nextCycle = 0;
    this.pending = new Set();
    this.pitches = [];
    this.decoding = {};
    ["select", "place", "move", "damage", "destroy"].forEach((x) =>
      this.load(x)
    );
    // Listen for user input
    this.ready = new Promise((r) => (this.setReady = r));
    // this.createContext = this.createContext.bind(this);
    // for (const event of TRUSTED_EVENTS) {
    //   window.addEventListener(event, this.createContext);
    // }
    // this.createContext();
  }

  get isMuted() {
    return this._isMuted;
  }
  set isMuted(value) {
    this._isMuted = Boolean(value);
    if (!this._isMuted) {
      this.context?.suspend();
    } else {
      // this.context?.resume();
      this.createContext();
    }
  }

  async createContext() {
    if (this.context) {
      if (this.context.state !== "running") {
        this.ready = new Promise((r) => this.context.resume().then(r));
      }
      return;
    }
    // for (const event of TRUSTED_EVENTS) {
    //   window.removeEventListener(event, this.createContext);
    // }
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    const decoding = Object.entries(this.decoding);
    this.decoding = {};
    try {
      const pitchRatio = 0.125;
      const pitchRef = {};
      for (const pitch of PITCH_CYCLE) {
        if (!pitch) {
          this.pitches.push(this.context.destination);
          continue;
        }
        let input = pitchRef[pitch];
        if (!input) {
          const jungle = new Jungle(this.context);
          jungle.setPitchOffset(pitch * pitchRatio);
          jungle.output.connect(this.context.destination);
          input = pitchRef[pitch] = jungle.input;
        }
        this.pitches.push(input);
      }
    } catch (e) {
      console.log("Error creating pitch changer");
      console.error(e);
      this.pitches = [this.context.destination];
    }
    await Promise.all(decoding.map((x) => this.decode(...x)));
    await sleep();
    this.setReady();
  }

  async decode(name, buffer) {
    if (!this.context) {
      this.decoding[name] = buffer;
      return;
    }
    this.buffers[name] = await this.context.decodeAudioData(buffer);
  }

  async load(name, extension = "wav") {
    const response = await fetch(`/sounds/${name}.${extension}`);
    const buffer = await response.arrayBuffer();
    this.decode(name, buffer);
  }

  async play(name) {
    if (this.isMuted) {
      return;
    }
    this.createContext();
    await Promise.race([this.ready, sleep(50)]);
    if (!this.context) {
      return;
    }
    // console.log(`Playing sound: ${name}`);
    const buffer = this.buffers[name];
    if (!buffer) {
      console.warn(`Sound "${name}" does not exist.`);
      return;
    }
    // if (Game.state !== 'in-game') {
    //   this.update(performance.now());
    // }
    if (this.pending.has(name)) {
      return;
    }
    this.pending.add(name);
    const source = this.context.createBufferSource();
    source.buffer = buffer;
    // source.connect(this.context.destination);
    source.connect(this.pitches[this.cycle] ?? this.context.destination);
    source.start(0);
    this.nextCycle = (this.cycle + 1) % this.pitches.length;
  }

  // Updates the pitch cycle, if necessary
  update(timestamp) {
    if (this.cycle === this.nextCycle) {
      return;
    }
    this.cycle = this.nextCycle;
    this.pending.clear();
  }
})();

export default Sound;

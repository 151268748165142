import { COLOR, FONT, MAX_X, MAX_Y, MIN_X, MIN_Y } from "./constants";
import { PATTERNS } from "./pattern";
import { angleTo, clamp, distanceBetween, drawPolygon } from "./util";

const Layers = new (class {
  constructor() {
    // Layers in the order they should be rendered to the canvas
    this.ordered = [];
    // .idString reference for every layer
    this.reference = {};
    // Keeps track of unique types that are currently being rendered
    this.types = new Set();
  }

  // Adds `value` to the layer with the given `type` and `id`, creating it
  // if necessary. (`removePrevious` indicates whether to remove this value
  // from other layers of the same type before adding it to this layer.)
  add(type, id, value, removePrevious = true) {
    if (type === "string") {
      throw new Error(`Layers.add expects a class for type, not a string.`);
    }
    if (removePrevious) {
      value?.$layers?.[type.type]?.remove(value);
    }
    const idString = this.getIdString(id);
    const key = `${type.type}:${idString}`;
    let layer = this.reference[key];
    if (!layer) {
      layer = this.reference[key] = new type(id, idString);
      layer.idString = idString;
      layer.key = key;
      this.types.add(layer.type);
      this.updateOrder();
    }
    layer.add(value);
    if (!value.$layers) {
      value.$layers = {};
    }
    value.$layers[type.type] = layer;
    return layer;
  }

  getIdString(id = {}) {
    if (!id || typeof id !== "object") {
      return id;
    }
    return Object.keys(id)
      .sort()
      .map((x) => `${x}=${id[x]}`)
      .join(":");
  }

  // Removes layers that don't have values
  prune() {
    this.types.clear();
    for (let i = this.ordered.length; i--; ) {
      const layer = this.ordered[i];
      if (layer.values.length) {
        this.types.add(layer.type);
        continue;
      }
      layer.values.splice(i, 1);
      delete this.reference[layer.key];
    }
  }

  // This removes the given value from any layer that contains it
  remove(value, type = null) {
    for (const layer of this.ordered) {
      if (type && layer instanceof type === false) {
        continue;
      }
      layer.remove(value);
    }
  }

  // Removes every single layer
  removeAll() {
    for (const layer of this.ordered) {
      for (const value of layer.values.slice()) {
        layer.remove(value);
      }
    }
    this.prune();
  }

  // Renders all layers in order
  render(ctx, timestamp) {
    for (const layer of this.ordered) {
      if (layer.values.length) {
        layer.render(ctx, timestamp);
      }
    }
  }

  // Updates layer order to ensure things are rendered properly
  updateOrder() {
    this.ordered = Object.values(this.reference).sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
      // TODO
      return 0;
    });
  }
})();

export default Layers;

// Testing!
window.Layers = Layers;

// Abstract class extended for specific layers of related rendering operations
class Layer {
  constructor(id) {
    if (!this.constructor.type) {
      throw new Error(`${this.constructor.name}.type is not defined!`);
    }
    this.type = this.constructor.type;
    this.priority = 0;
    this.id = id;
    this.idString = "";
    this.key = "";
    this.values = [];
    this.prepare();
  }

  get value() {
    return this.values[0];
  }

  add(value) {
    if (this.values.includes(value)) {
      return;
    }
    this.values.push(value);
  }

  prepare() {
    // Overridden by classes that extend this
  }

  remove(value) {
    const index = this.values.indexOf(value);
    if (index === -1) {
      return;
    }
    this.values.splice(index, 1);
    if (value.$layers[this.type] === this) {
      value.$layers[this.type] = null;
    }
  }

  render(ctx, timestamp) {
    throw new Error(`${this.constructor.name}.render is not implemented!`);
  }
}

export class GloveLayer extends Layer {
  static get type() {
    return "Glove";
  }

  prepare() {
    this.priority = 20;
  }

  render(ctx) {
    // draw transition history
    ctx.strokeStyle = this.id.color;
    // ctx.globalAlpha = 1;
    ctx.lineCap = "round";
    for (const value of this.values) {
      const { history } = value;
      const x = history.x[0];
      const y = history.y[0];
      const r = history.r[0];
      if (x == null || y == null || r == null) continue;

      const c = history.x.length;
      const i1 = Math.floor((c * 1) / 3);
      const i2 = Math.floor((c * 2) / 3);

      const x1 = history.x[i1];
      const y1 = history.y[i1];

      const x2 = history.x[i2];
      const y2 = history.y[i2];

      ctx.lineWidth = value.r + r;
      ctx.beginPath();
      ctx.moveTo(value.x, value.y);
      // ctx.lineTo(x, y);
      ctx.bezierCurveTo(x2, y2, x1, y1, x, y);
      ctx.stroke();
    }

    // draw actual position
    ctx.fillStyle = this.id.color;
    ctx.lineWidth = 1;
    // ctx.globalAlpha = 1;
    ctx.beginPath();
    for (const value of this.values) {
      ctx.moveTo(value.x, value.y);
      ctx.arc(value.x, value.y, value.r, 0, Math.PI * 2);
    }
    ctx.fill();
  }
}

export class InputDebugLayer extends Layer {
  static get type() {
    return "InputDebug";
  }

  prepare() {
    this.priority = 21;
  }

  render(ctx) {
    const PAD = 12;
    ctx.textBaseline = "bottom";
    ctx.textAlign = "left";
    ctx.fillText(`L: ${this.value.left.position}`, MIN_X + PAD, MAX_Y - PAD);
    ctx.textAlign = "right";
    ctx.fillText(`R: ${this.value.right.position}`, MAX_X - PAD, MAX_Y - PAD);
  }
}

if (!window.leftInput && !window.rightInput) {
  window.leftInput = { x: 0, y: 0 };
  window.rightInput = { x: 0, y: 0 };
}
window.clientGamepad = null;
window.lastInputType = "keyboard";

/// Listen for keyboard events
const leftKeys = {
  input: window.leftInput,
  keys: {
    left: "A",
    right: "D",
    top: "W",
    bottom: "S",
  },
  held: {
    left: false,
    right: false,
    top: false,
    bottom: false,
  },
};
const rightKeys = {
  input: window.rightInput,
  keys: {
    left: "ArrowLeft",
    right: "ArrowRight",
    top: "ArrowUp",
    bottom: "ArrowDown",
  },
  held: {
    left: false,
    right: false,
    top: false,
    bottom: false,
  },
};
const eventKey = (key) => {
  if (key.length === 1) {
    return key.toUpperCase();
  }
  return key;
};
const updateKeys = (held, input) => {
  input.x = held.right - held.left;
  input.y = held.bottom - held.top;
};
window.addEventListener("keydown", (e) => {
  const k = eventKey(e.key);
  for (const { input, held, keys } of [leftKeys, rightKeys]) {
    for (const [position, key] of Object.entries(keys)) {
      if (k !== key) continue;
      held[position] = true;
      updateKeys(held, input);
      return;
    }
  }
});
window.addEventListener("keyup", (e) => {
  const k = eventKey(e.key);
  for (const { input, held, keys } of [leftKeys, rightKeys]) {
    for (const [position, key] of Object.entries(keys)) {
      if (k !== key) continue;
      held[position] = false;
      updateKeys(held, input);
      return;
    }
  }
});

/// Listen for touch events
const leftTouch = {
  input: window.leftInput,
  id: -1,
  startX: 0,
  startY: 0,
  x: 0,
  y: 0,
};
const rightTouch = {
  input: window.rightInput,
  id: -1,
  startX: 0,
  startY: 0,
  x: 0,
  y: 0,
};
const touchMargin = 16;
window.addEventListener(
  "touchstart",
  (e) => {
    window.lastInputType = "touch";
    for (const touch of e.touches) {
      if (touch.clientX < window.innerWidth * 0.5) {
        if (leftTouch.id !== -1) continue;
        leftTouch.id = touch.identifier;
        leftTouch.startX = leftTouch.x = touch.clientX;
        leftTouch.startY = leftTouch.y = touch.clientY;
        leftTouch.input.x = 0;
        leftTouch.input.y = 0;
      } else {
        if (rightTouch.id !== -1) continue;
        rightTouch.id = touch.identifier;
        rightTouch.startX = rightTouch.x = touch.clientX;
        rightTouch.startY = rightTouch.y = touch.clientY;
        rightTouch.input.x = 0;
        rightTouch.input.y = 0;
      }
    }
  },
  {
    passive: false,
  }
);
const onTouchEnd = (e) => {
  for (const touch of e.changedTouches) {
    let t;
    if (leftTouch.id === touch.identifier) {
      t = leftTouch;
    } else if (rightTouch.id === touch.identifier) {
      t = rightTouch;
    } else {
      continue;
    }
    t.id = -1;
  }
};
window.addEventListener("touchend", onTouchEnd);
window.addEventListener("touchcancel", onTouchEnd);
window.addEventListener(
  "touchmove",
  (e) => {
    e.preventDefault();
    window.lastInputType = "touch";
    // console.log(`IDs: %d + %d`, leftTouch.id, rightTouch.id);
    for (const touch of e.touches) {
      let t;
      if (leftTouch.id === touch.identifier) {
        t = leftTouch;
      } else if (rightTouch.id === touch.identifier) {
        t = rightTouch;
      } else {
        continue;
      }
      t.x = touch.clientX;
      t.y = touch.clientY;
      const x = t.x - t.startX;
      const y = t.y - t.startY;
      const xAbs = Math.abs(x);
      const yAbs = Math.abs(y);
      if (xAbs < touchMargin && yAbs < touchMargin) {
        t.input.x = 0;
        t.input.y = 0;
      } else if (xAbs > yAbs) {
        t.input.x = Math.sign(x);
        t.input.y = 0;
      } else {
        t.input.x = 0;
        t.input.y = Math.sign(y);
      }
    }
  },
  { passive: false }
);

class ClientGamepad {
  constructor(index) {
    // Index used to get the current gamepad state from navigator
    this.index = index;
    // Controls that are being polled for this game
    this.left = new ClientGamepadJoystick(0, 1);
    this.a = new ClientGamepadButton(0);
    this.b = new ClientGamepadButton(1);
    this.x = new ClientGamepadButton(2);
    this.y = new ClientGamepadButton(3);
    // State that determines what can currently be done with a controller
    this.currentOption = [0, 0]; // The current [x, y] pair
    this.options = []; // Determines valid client [x, y] pairs
  }

  get gamepad() {
    return navigator.getGamepads()[this.index];
  }

  get debugString() {
    return `Gamepad(left: ${this.left.debugString}, a: ${this.a.debugString}, b: ${this.b.debugString}, x: ${this.x.debugString}, y: ${this.y.debugString})`;
  }

  poll() {
    const { gamepad } = this;
    if (!gamepad) {
      // TODO: reset to default state?
      // console.error(`Could not find gamepad#${this.index}`);
      return;
    }
    this.left.poll(gamepad);
    this.a.poll(gamepad);
    this.b.poll(gamepad);
    this.x.poll(gamepad);
    this.y.poll(gamepad);
  }

  update() {
    this.poll();
    if (this.options.length && (this.left.pressed || this.left.repeated)) {
      const c = this.currentOption;
      if (!this.options.some(([x, y]) => x === c[0] && y === c[1])) {
        this.currentOption = this.options[0].slice();
        window.clientMoved = true;
      } else {
        const hoverDistance = 64;
        const hoverDistance2 = hoverDistance ** 2;
        for (let distance = 32; distance < 800; distance += 8) {
          const dx = c[0] + distance * Math.cos(this.left.angle);
          const dy = c[1] + distance * Math.sin(this.left.angle);
          let closest = null,
            closestDistance = Infinity;
          for (const [x, y] of this.options) {
            if (x === c[0] && y === c[1]) {
              continue;
            }
            const d2 = (x - dx) ** 2 + (y - dy) ** 2;
            if (d2 < closestDistance) {
              closest = [x, y];
              closestDistance = d2;
            }
          }
          if (closestDistance <= hoverDistance2) {
            this.currentOption = closest;
            window.clientMoved = true;
            break;
          }
        }
      }
    }
    if (window.lastInputType === "gamepad") {
      // Impersonate a pointer (shh...)
      window.clientX = this.currentOption[0];
      window.clientY = this.currentOption[1];
      window.clientPressed = this.a.pressed;
      window.clientHeld = this.a.held;
      window.clientReleased = this.a.released;
    }
    // Handle button callbacks
    this.a.update();
    this.b.update();
    this.x.update();
    this.y.update();
  }

  vibrate(intensity = 1, duration = 100) {
    const { gamepad } = this;
    if (!gamepad) {
      return;
    }
    gamepad.hapticActuators?.[0]?.pulse(intensity, duration);
    gamepad.vibrationActuator?.playEffect("dual-rumble", {
      startDelay: 0,
      duration: duration,
      weakMagnitude: intensity,
      strongMagnitude: intensity,
    });
  }
}

class ClientGamepadJoystick {
  constructor(xIndex, yIndex) {
    this.xIndex = xIndex;
    this.yIndex = yIndex;
    this.x = 0;
    this.y = 0;
    this.distance = 0;
    this.angle = 0;
    this.pressed = false;
    this.held = false;
    this.released = false;
    // Interval repeat state (similar to keydown)
    this.repeated = false;
    this.repeatInterval = 125;
    this.repeatTimestamp = 0;
  }

  get state() {
    if (this.pressed) {
      return "pressed";
    } else if (this.repeated) {
      return "repeated";
    } else if (this.held) {
      return "held";
    } else if (this.released) {
      return "released";
    }
    return "inert";
  }

  get debugString() {
    return `${this.state}:[${this.x.toFixed(2)},${this.y.toFixed(2)}]`;
  }

  poll(gamepad) {
    this.x = gamepad.axes[this.xIndex];
    this.y = gamepad.axes[this.yIndex];
    this.distance = Math.sqrt(this.x ** 2 + this.y ** 2) * 0.5;
    this.angle = Math.atan2(this.y, this.x);
    const held = this.distance >= 0.3;
    if (held && !this.held) {
      this.pressed = true;
      this.held = true;
      this.repeatTimestamp = performance.now() + this.repeatInterval * 2.5;
      window.lastInputType = "gamepad";
    } else if (!held && this.held) {
      this.released = true;
      this.held = false;
    } else {
      if (this.pressed) {
        this.pressed = false;
      }
      if (this.repeated) {
        this.repeated = false;
      } else if (this.held && performance.now() >= this.repeatTimestamp) {
        this.repeated = true;
        this.repeatTimestamp += this.repeatInterval;
      }
      if (this.released) {
        this.released = false;
      }
    }
  }
}

class ClientGamepadButton {
  constructor(index) {
    this.index = index;
    this.pressed = false;
    this.held = false;
    this.released = false;
    this.callback = null;
    this.lastPress = 0;
  }

  get state() {
    if (this.pressed) {
      return "pressed";
    } else if (this.held) {
      return "held";
    } else if (this.released) {
      return "released";
    }
    return "inert";
  }

  get debugString() {
    return this.state;
  }

  poll(gamepad) {
    const held = gamepad.buttons[this.index].pressed;
    if (held && !this.held) {
      const now = Date.now();
      const delta = now - this.lastPress;
      // Incredibly rapid taps are probably a glitchy gampepad
      // source: my xbox controllers
      if (delta < 300) {
        return;
      }
      this.lastPress = now;
      this.pressed = true;
      this.held = true;
      window.lastInputType = "gamepad";
    } else if (!held && this.held) {
      this.released = true;
      this.held = false;
    } else {
      if (this.pressed) {
        this.pressed = false;
      }
      if (this.released) {
        this.released = false;
      }
    }
  }

  update() {
    if (this.pressed && this.callback) {
      this.callback();
    }
  }
}

window.addEventListener("gamepadconnected", (e) => {
  console.log(e);
  window.clientGamepad = new ClientGamepad(0);
  window.lastInputType = "gamepad";
  window.Grid?.updateGamepadOptions();
});

// TODO: listen for disconnect

import { angleDelta, angleTo, clamp, distanceBetween, sinLerp } from "./util";
import {
  GLOVE_R_ATTACK,
  GLOVE_R_BLOCK,
  GLOVE_R_IDLE,
  MAX_X,
  MAX_Y,
  MIN_X,
  MIN_Y,
} from "./constants";
import Layers, { GloveLayer, InputDebugLayer } from "./layers";

class PlayerController {
  constructor() {
    this.left = new Glove({
      side: "left",
      input: window.leftInput,
      x: MIN_X * 0.4,
      y: MAX_Y * 0.5,
    });
    this.right = new Glove({
      side: "right",
      input: window.rightInput,
      x: MAX_X * 0.4,
      y: MAX_Y * 0.5,
    });
    // Set initial layers
    this.setLayers();
  }

  // TODO: json for saving

  update(timestamp, delta) {
    this.left.update(timestamp);
    this.right.update(timestamp);
  }

  setLayers() {
    Layers.remove(this);
    Layers.add(InputDebugLayer, null, this);
    this.left.setLayers();
    this.right.setLayers();
  }
}

class Glove {
  constructor({
    side = "left",
    input = { x: 0, y: 0 },
    x = 0,
    y = 0,
    color = "red",
  } = {}) {
    this.side = side;
    this.input = input;
    this.xStart = x;
    this.yStart = y;
    this.x = x;
    this.y = y;
    this.r = GLOVE_R_IDLE;
    this.color = color;
    this.position = "inert";
    this.combo = [];
    this.transitions = null;
    this.history = {
      x: [this.x],
      y: [this.y],
      r: [this.r],
    };
    this.setLayers();
  }

  update(timestamp) {
    // TODO: move this so that AI can also set position
    this.setPosition(this.pollPosition());
    if (this.transitions) {
      let allDone = true;
      for (const [property, transition] of Object.entries(this.transitions)) {
        transition.update(timestamp);
        this[property] = transition.value;
        const history = this.history[property];
        history.push(transition.value);
        if (history.length > 6) history.shift();
        if (!transition.isDone) allDone = false;
      }
      if (allDone) {
        this.transitions = null;
        this.combo.splice(0);
        if (this.position !== "inert") {
          this.combo.push(this.position);
        }
      }
    } else {
      if (this.history.x.length) this.history.x.shift();
      if (this.history.y.length) this.history.y.shift();
      if (this.history.r.length) this.history.r.shift();
    }
  }

  pollPosition() {
    const { x, y } = this.input;
    const xAbs = Math.abs(x);
    const yAbs = Math.abs(y);
    const margin = 0.25;
    if (xAbs < margin && yAbs < margin) {
      return "inert";
    }
    if (xAbs > yAbs) {
      if ((this.side === "left" && x < 0) || (this.side === "right" && x > 0)) {
        return "outside";
      }
      return "inside";
    } else {
      if (y < 0) {
        return "front";
      }
      return "back";
    }
  }

  setPosition(position) {
    if (this.position === position) return;
    this.position = position;
    const previous = this.combo.at(-1);
    if (position !== "inert") {
      this.combo.push(position);
    }
    // TODO: handle combos
    // Determine target values
    const xSign = Math.sign(this.xStart);
    const middle = {
      x: null,
      y: null,
      r: null,
    };
    const target = {
      x: this.xStart,
      y: this.yStart,
      r: GLOVE_R_IDLE,
    };
    if (this.position === "front") {
      target.y = MIN_Y + GLOVE_R_ATTACK;
      target.r = GLOVE_R_ATTACK;
      // Combos
      if (previous === "outside") {
        middle.x = this.xStart + 48 * xSign;
        target.x = this.xStart + 48 * xSign;
      } else if (previous === "inside") {
        middle.x = this.xStart - 48 * xSign;
        target.x = this.xStart * -1;
      }
    } else if (this.position === "back") {
      target.x -= 24 * xSign;
      target.y = MAX_Y - GLOVE_R_BLOCK * 0.5;
      target.r = GLOVE_R_BLOCK;
      // Combos
      if (previous === "outside") {
        target.x = this.xStart + 64 * xSign;
      } else if (previous === "inside") {
        target.x = this.xStart * -1;
      }
    } else if (this.position === "outside") {
      target.x += 64 * xSign;
      if (previous === "back") {
        target.y = MAX_Y - GLOVE_R_BLOCK * 0.5;
        target.r = GLOVE_R_BLOCK;
      }
    } else if (this.position === "inside") {
      target.x = GLOVE_R_IDLE * xSign;
      if (previous === "back") {
        target.x = this.xStart * -1;
        target.y = MAX_Y - GLOVE_R_BLOCK * 0.5;
        target.r = GLOVE_R_BLOCK;
      }
    }
    // Flip for opponent
    if (this.yStart < 0) {
      target.y *= -1;
      if (middle.y !== null) {
        middle.y *= -1;
      }
    }
    // Create transition
    const d = distanceBetween(this.x, this.y, target.x, target.y);
    const duration = 30 * Math.log(d);
    this.transitions = {};
    for (const property of "xyr") {
      this.transitions[property] = new GloveTransition({
        keyframes: [this[property], middle[property], target[property]].filter(
          (x) => x !== null
        ),
        duration,
      });
    }
  }

  setLayers() {
    Layers.remove(this);
    Layers.add(GloveLayer, { color: this.color }, this);
  }
}

class GloveTransition {
  constructor({ keyframes = [], duration = 300, ease = sinLerp } = {}) {
    if (keyframes.length < 2) {
      throw new Error("Transitions require at least 2 keyframes.");
    }
    this.keyframes = keyframes;
    this.deltas = keyframes.slice(1).map((x, i) => x - keyframes[i]);
    this.duration = duration;
    this.timestamp = performance.now();
    this.period = 1 / this.deltas.length + Number.EPSILON;
    this.ease = ease;
    this.value = keyframes[0];
    this.isDone = false;
  }

  update(timestamp) {
    if (this.isDone) return;
    const rawT = clamp(0, (timestamp - this.timestamp) / this.duration, 1);
    const index = Math.min(
      this.deltas.length - 1,
      Math.floor(rawT / this.period)
    );
    const periodT = rawT % this.period;
    const t = this.ease(periodT);
    this.value = this.keyframes[index] + t * this.deltas[index];
    if (rawT === 1) this.isDone = true;
  }
}

export const Player = new PlayerController();

window.Player = Player;

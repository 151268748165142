import { Camera } from "./camera";
import { Player } from "./player";
import Layers from "./layers";
// import Settings from "./settings";
import Sound from "./sound";

// const startingTimestamp = performance.now();
// const gracePeriod = 3000;

// Primary render function
export default function render(canvas, ctx, timestamp, delta) {
  // These are some special states where a render is always needed, beyond this
  // changes to Layers will always trigger a new render
  // if (window.clientMoved || window.clientPressed || window.clientReleased ||
  //   Camera.startT ||
  //   timestamp - startingTimestamp <= gracePeriod) {
  //   Game.renderNeeded = true;
  // }
  Sound.update(timestamp);
  Player.update(timestamp, delta);
  // if (!Game.renderNeeded) {
  //   return;
  // }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  Camera.frame(ctx, timestamp, delta);
  Layers.render(ctx, timestamp);

  // Game.renderNeeded = false;
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  // // TEMP: Gamepad debug
  // if (window.clientGamepad) {
  //   ctx.fillStyle = 'white';
  //   ctx.textBaseline = 'top';
  //   ctx.textAlign = 'left';
  //   ctx.font = '18px monospace';
  //   ctx.fillText(window.clientGamepad.debugString, 8, 8);
  // }
}
